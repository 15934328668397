import "./array.js";

import React, { useEffect, useState } from "react";

import DateModal from "./dateModal";
import ExcelDownload from "./excel";
import ExcelExport from "../oderplan/excelexport";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import Loader from "../../common/loader";
import Subplan from "./subplan";
import html2canvas from "html2canvas";
import { useData } from "../../../utils/dataProvider";

function Plan() {
  const { getModifiedTime, makeApiRequest, setAppTitle } = useData();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [select, setSelect] = useState("");
  const [time, setTime] = useState(new Date());

  const [startDatum, setStartDatum] = useState("");
  const [endDatum, setEndDatum] = useState("");
  const [days, setDays] = useState([]);
  const [dayArray, setDayArray] = useState([]);
  const [monthArray, setArray] = useState();
  const [calendarWeek, setCalendarWeek] = useState([]);
  const [ie, setIe] = useState(false);
  const [titleAbsolute, setTitleAbsolute] = useState(false);
  const [modal, modalHandel] = useState();

  useEffect(() => {
    async function fetchData() {
      const apiData = await makeApiRequest("/currentplan");
      setData(apiData.data);
      setStartDatum(apiData.startdatum);
      setEndDatum(apiData.enddatum);

      setTime(getModifiedTime(apiData.modified), true);
      setLoading(false);
    }
    fetchData();
    setAppTitle("Plan");
  }, []);
  useEffect(() => {
    var ua = window.navigator.userAgent;
    var msie = ua.indexOf("MSIE ");
    var trident = ua.indexOf("Trident/");
    if (msie > 0 || trident > 0) {
      setIe(true);
    }
  }, []);

  function deviceRedirect() {
    if (/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)) {
      return true;
    } else {
      return false;
    }
  }

  useEffect(() => {
    const wholeCalendarWeekName = [
      { name: "Jan", days: 31 },
      { name: "Feb", days: 28 },
      { name: "Mär", days: 31 },
      { name: "Apr", days: 30 },
      { name: "Mai", days: 31 },
      { name: "Jun", days: 30 },
      { name: "Jul", days: 31 },
      { name: "Aug", days: 31 },
      { name: "Sep", days: 30 },
      { name: "Okt", days: 31 },
      { name: "Nov", days: 30 },
      { name: "Dez", days: 31 },
    ];
    setDays(getOffset(startDatum, endDatum));
    let empty = [];
    for (let i = 0; i <= getOffset(startDatum, endDatum); i++) {
      empty.push({
        offset: i,
        date: new Date(startDatum * 1000 + i * 24 * 60 * 60 * 1000),
      });
    }
    setDayArray(empty);
    setArray(getMonthArray(empty, wholeCalendarWeekName));
    setCalendarWeek(getCalendarWeek(empty));
    setSelect(
      Array(getMonthArray(empty, wholeCalendarWeekName).length).fill(false)
    );
  }, [startDatum, endDatum]);

  useEffect(() => {
    window.addEventListener("scroll", MonthFixed);
  }, [window.pageYOffset]);

  function selectMonth(month) {
    let monthList = select;
    monthList[month] = !monthList[month];
    setSelect([...monthList]);
  }

  function getOffset(date0, date1) {
    const diffTime = Math.abs(date0 * 1000 - date1 * 1000);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays;
  }

  const getMonthArray = (totaldays, monthName) => {
    if (totaldays[0]) {
      var month = [monthName[totaldays[0].date.getMonth()]];
      for (let i = 0; i < totaldays.length; i++) {
        if (totaldays[i + 1]) {
          let currentMonth = totaldays[i + 1].date.getMonth();
          let prevMonth = totaldays[i].date.getMonth();
          if (currentMonth !== prevMonth) {
            month.push(monthName[currentMonth]);
          }
        }
      }
    }

    return month;
  };
  const setMonthLength = (MonthArray) => {
    let gridStyle = "";
    for (let i = 0; i < MonthArray.length; i++) {
      gridStyle = gridStyle + ` ${MonthArray[i].days}fr`;
    }
    return gridStyle;
  };

  function MonthFixed() {
    if (window.pageYOffset > 220) {
      setTitleAbsolute(window.pageYOffset);
    } else {
      setTitleAbsolute(false);
    }
  }

  function addNull(num) {
    return num >= 10 ? num : "0" + num;
  }

  function getCalendarWeek(days) {
    let calendarWeek = [];
    let currentWeek = 0;
    days.map((day, index) => {
      let week = day.date.getWeek();
      if (week != currentWeek) {
        calendarWeek.push(week);
        currentWeek = week;
      }
    });
    return calendarWeek;
  }

  const print = () => {
    window.print();
  };

  if (loading) {
    return <Loader />;
  } else {
    return (
      <div className="plan" id="marketing-plan">
        <div
          className="start-title"
          style={{ width: "1800px", margin: "1.5rem auto" }}
        >
          <h1>Marketing- und Vertriebsplan 2024</h1>

          <div className="start-time">
            <span className="time">Letzte Aktualisierung am {time}</span>
            <br />
            <span className="print" onClick={print}>
              <i className="icon-print" />
              Drucken
            </span>
          </div>
        </div>

        <ExcelDownload data={data} />
        <div className="table">
          <div
            className="plan-content plan-big-title"
            style={
              titleAbsolute
                ? {
                    position: "absolute",
                    top: `${titleAbsolute - 263}px`,
                    left: "0",
                  }
                : {}
            }
          >
            <div className="plan-sub-title">Titel</div>
            <div
              className="month-title"
              style={{
                gridTemplateColumns: `${setMonthLength(monthArray)}`,
              }}
            >
              {monthArray && !ie ? (
                monthArray.map((month, index) => {
                  return (
                    <div
                      className={`month-list ${
                        select[index] ? "selected" : ""
                      }`}
                      onClick={() => selectMonth(index)}
                      key={`month-list-${index}`}
                    >
                      {month.name}
                    </div>
                  );
                })
              ) : (
                <div>Kampagnen</div>
              )}
            </div>
          </div>
          <div
            className="calendar-week-container"
            style={
              titleAbsolute
                ? {
                    position: "absolute",
                    top: `${titleAbsolute - 203}px`,
                    left: "0",
                  }
                : {}
            }
          >
            <div className="calendar-title">Kalenderwoche</div>
            <div className="calendar-week-list">
              {dayArray &&
                dayArray.map((day, idx) => {
                  const remain = (idx + 1) % 7;
                  if (remain === 0 || idx === 0) {
                    let week;
                    if (idx === 0) {
                      week = 1;
                    } else {
                      week = (idx + 8) / 7;
                    }
                    return (
                      <div
                        className="week-line"
                        style={{
                          borderLeft: "1px solid #CCC",
                          height: "100%",
                          gridColumnStart: idx === 0 ? 1 : idx + 2,
                          gridColumnEnd: idx === 0 ? 8 : idx + 9,
                        }}
                        key={`weekline-${idx}`}
                        onMouseOver={() => {
                          new Date().getWeek() === calendarWeek[week - 1] &&
                            week < 48 &&
                            modalHandel(true);
                        }}
                        onMouseLeave={() => modalHandel(false)}
                      >
                        {modal ? <DateModal /> : null}
                        <div
                          className={`week-point-square ${
                            new Date().getWeek() === calendarWeek[week - 1]
                              ? "active"
                              : ""
                          } `}
                        ></div>
                        <div
                          className={`week-point-arrow ${
                            new Date().getWeek() === calendarWeek[week - 1]
                              ? "active"
                              : ""
                          } `}
                        ></div>
                        <div className="week-number">
                          {addNull(calendarWeek[week - 1])}
                        </div>
                      </div>
                    );
                  } else {
                    return null;
                  }
                })}
            </div>
          </div>
          <div className="plan-main-content">
            {data &&
              data.map((item, index) => {
                const childRef = React.createRef();
                const updateChildState = () => {
                  childRef.current.changeVisible();
                };
                const itemVisible = () => {
                  return childRef.current.itemVisible;
                };

                return (
                  <Plandata.Provider
                    value={{
                      item,
                      select,
                      startDatum,
                      endDatum,
                      dayArray,
                      days,
                    }}
                    key={`plan-title-${index}`}
                  >
                    <div
                      className={"plan-title " + `${() => itemVisible()}`}
                      onClick={updateChildState}
                    >
                      <span>{item.name}</span>
                    </div>

                    <Subplan ref={childRef} />
                  </Plandata.Provider>
                );
              })}
          </div>
        </div>
        <Helmet>
          <title>Toolbox Marketing- und Vertriebsplan</title>
        </Helmet>
      </div>
    );
  }
}
export const Plandata = React.createContext();
export default Plan;
